.modo_main {
    width: 100%;
    height: 1300px;
    margin: auto;
    font-family: "Mulish", sans-serif;
}

.modo_img {
    width: 100%;
    height: 50%;
    margin: auto;
}

.nuestro_modo {
    position: relative;
    top: -370px;
    height: 0;
}

.nuestro_modo h1{
    font-size: 80px;
    opacity: 0.8;
}


.modo_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}

.modo_paneles {
    width: 100%;
    height: 50%;
    margin: auto;
    background-color: #dfdddd;
}

.panel_margen {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    margin: auto;
    padding: 0 6%;
    height: 50%;
}

.panel_rep {
    width: 22%;
    height: 400px;
    background-color: black;
    opacity: 0.5;
    border-radius: 2px;
    box-shadow: 4px 4px rgba(0, 0, 0, 0.8);
    position: relative;
    top: -180px;
}

.panel_rep h2{
    font-size: 27px;
    color: white;
    font-weight: lighter;
    margin: 10%;
    text-align: left;
}

.modo_paneles h3 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 60px;
    position: relative;
    top: -100px;
    margin: 50px;
}

.modo_paneles h4 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 30px;
    margin: auto;
    position: relative;
    top: -100px;
    width: 70%;
}

@media (max-width: 1500px) {
    .nuestro_modo {
        top: -430px;
    }

    .panel_margen {
        display: flex;
        height: 60%;
    }

    .panel_rep h2{
        font-size: 23px;
    }

    .modo_paneles h3 {
        font-size: 40px;
        margin: 40px;
    }
    
    .modo_paneles h4 {
        font-size: 28px;
    }
}

@media (max-width:700px) {
    .panel_margen {
        display: flex;
 
        flex-direction: column;
    }

    .panel_rep {
        width: 95%;
        height: 300px;
        position: relative;
        top: -200px;
        margin: 15px 0;
    }

    .panel_rep h2{
        font-size: 27px;
        margin: 5%;
    }

    .nuestro_modo h1{
        font-size: 40px;
        opacity: 0.8;
    }

    .nuestro_modo {
        top: -510px;
    }

    .panel_margen {
        display: flex;
        height: 60%;
    }

    .panel_rep h2{
        font-size: 18px;
    }

    .modo_paneles h3 {
        font-size: 30px;
        margin: 40px;
    }
    
    .modo_paneles h4 {
        font-size: 20px;
        width: 95%;
    }
}