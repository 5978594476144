.novedades_main {
    width: 100%;
    height: auto;
    margin: auto;
    background-color: #dfdddd;
    font-family: "Mulish", sans-serif;
}

#img_chevron {
    width: 50px;
}

.novedades_main h1{
    font-size: 70px;
    font-weight: lighter;
    text-align: left;
    padding: 40px 0 50px 180px;
}

.novedades_container {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: auto;
    padding-bottom: 100px;
}

.novedades_column_1 {
    width: 50%;
}

.novedades_column_1 img{
    width: 100%;
}

.novedades_column_2 {
    width: 50%;
    background-color: white;
}

.novedades_column_2 h2{
    font-size: 30px;
    text-align: left;
    margin: 42px 0px 0px 42px;
    font-weight: lighter;
}

.novedades_column_2 h3{
    font-size: 16px;
    font-weight: lighter;
    text-align: left;
    margin: 42px;
    line-height: 23px;
}

.btn_arriba_novedades {
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    width: 20%;
    cursor: pointer;
}

.boton_para_arriba {
    width: 100%;
    margin: auto;
    display: flex;  
    justify-content: center;
}

.volver_al_inicio {
    width: 100%;
    margin: auto;
}

.volver_al_inicio img{
    opacity: 0.6;
}

.volver_al_inicio h1{
    font-size: 20px !important;
    font-weight: bold;
    margin: 0;
    padding: 15px !important;
    text-align: center;
    color: rgba(0,0,0, 0.8);
}



@media (max-width:1400px) {

    .novedades_main h1{
        font-size: 40px;
        padding: 40px 0 40px 135px;
    }
    
    .novedades_container {



        flex-direction: row;
        flex-wrap: nowrap;
        margin: auto;
        padding-bottom: 80px;
    }
    

    
    .novedades_column_2 h2{
        font-size: 25px;
        text-align: left;
        margin: 25px;
    }
    
    .novedades_column_2 h3{
        font-size: 16px;
        margin: 22px;
        line-height: 26px;
    }

    
}

@media (max-width:700px) {
 
    
    .novedades_main h1{
        font-size: 35px;
        padding: 40px 40px 25px 40px;
    }
    
    .novedades_container {
        flex-direction: column;
        flex-wrap: nowrap;
        margin: auto;
        padding-bottom: 80px;
    }
    
    .novedades_column_1 {
        width: 100%;
    }
    
    .novedades_column_2 {
        width: 100%;
    }
    
    .novedades_column_2 h2{
        font-size: 25px;
        text-align: left;
        margin: 25px;
    }
    
    .novedades_column_2 h3{
        font-size: 14px;
        margin: 22px;
        line-height: 25px;
    }
    .volver_al_inicio h1{
        font-size: 16px !important;
    }

}