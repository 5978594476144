.main_base {
    width: 95%;
    height: auto;
    max-width: 1550px;
    margin: auto;
    position: relative;
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Mulish", sans-serif;
}

.base_item {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    
}

.base_item_2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    
}

.main_text_base {
    width: 50%;
}

.main_text_base_segundo {
    width: 50%;
}

.main_text_base_segundo h1 {
    text-align: left;
    margin: 30% 35px 0 auto;
    width: 70%;
    font-weight: lighter;
}

.main_text_base h1 {
    font-size: 35pt;
    margin: 25% 0 0 35px;
    text-align: left;
    width: 70%;
    font-weight: lighter;
}

.base_img {
    width: 50%;
}

.base_img img {
    width: 80%;
}

.img_der {
    float: right;
}

.img_izq {
    float: left;
}

.gris_texto_base {
    width: 70%;
    height: auto;
    margin: 35px 35px 0 35px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.gris_texto_base_segundo {
    width: 70%;
    height: auto;
    margin: 35px 35px 0 auto;
    border-radius: 2%;
    background-color: rgba(0, 0, 0, 0.1);
}

.gris_texto_base  h2 {
    text-align: left;
    font-size: 19pt;
    color: rgba(0, 0, 0, 0.8);
    padding: 35px;
    font-weight: lighter;
    line-height: 40px;
}

.gris_texto_base_segundo h2 {
    text-align: left;
    font-size: 19pt;
    color: rgba(0, 0, 0, 0.8);
    padding: 35px;
    font-weight: lighter;
    line-height: 40px;
}

@media (max-width: 1500px) {
    .main_base {
        width: 80%;
    }

    .main_text_base_segundo h1 {
        margin: 25% 10% 0 auto;
        font-size: 24pt;
    }
    
    .main_text_base h1 {
        font-size: 24pt;
        margin: 21% auto 0 10%;
    }
    
    .base_img {
        width: 50%;
    }
    
    .base_img img {
        width: 65%;
    }
    
    .gris_texto_base {
        margin: 2% auto 0 10%;
    }
    
    .gris_texto_base_segundo {
        margin: 2% 10% 0 auto;
    }
    
    .gris_texto_base  h2 {
        font-size: 12pt;
        padding: 4%;
        line-height: 28px;
    }
    
    .gris_texto_base_segundo h2 {
        font-size: 12pt;
        padding: 4%;
        line-height: 28px;
    }

    .img_der {
        margin-right: 10%;
    }
    
    .img_izq {
        margin-left: 10%;
    }
    
}
@media (max-width: 700px) {
    .main_base {
        width: 100%;
    }
    .base_item {
        flex-direction: column;
    }

    .base_item_2 {
        flex-direction: column-reverse;
    }
    
    .main_base {
        width: 90%;
        height: auto;
    }

    .main_text_base {
        width: 100%;
    }
    
    .main_text_base_segundo {
        width: 100%;
    }

    .main_text_base_segundo h1 {
        margin: auto;
        font-size: 13pt;
        padding: 25px 0;
    }
    
    .main_text_base h1 {
        font-size: 13pt;
        margin: auto;
        padding: 25px 0;
    }
    
    .base_img {
        width: 100%;
    }
    
    .base_img img {
        width: 85%;
        margin: 25px 0;
    }
    
    .gris_texto_base {
        margin: auto;
    }
    
    .gris_texto_base_segundo {
        margin: auto;
    }
    
    .gris_texto_base  h2 {
        font-size: 10pt;
        padding: 4%;
        line-height: 18px;
    }
    
    .gris_texto_base_segundo h2 {
        font-size: 10pt;
        padding: 4%;
        line-height: 18px;
    }

    .img_der {
        margin-right: 0;
    }
    
    .img_izq {
        margin-left: 0;
    }
    
}