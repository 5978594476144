.cover_section {
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
}

.w-100 {
    height: 100vh;
    object-fit: cover;
    object-position: top;
}

.labels_captions {
    color: white;
}

.img_cover {
    width: 100%;
    height: auto;
}

@media(max-width: 600px) {
    .w-100 {
        height: 100vh;
        object-fit: cover;
        object-position: top;
    }
}