.main_bar {
    width: 100%;
    height: 110px;
    background-color: white;
    opacity: 0.6;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    font-family: "Mulish",sans-serif;
}

.logo_bar img{
    height: 60px;
    margin: 0px 20px;
    opacity: 1 !important;
}

.burger_navbar {
display: none;
}


.section_bar_options {
    list-style: none;
    margin: 0 50px;
}

.nav_bar_options {
    cursor: pointer;
}

.nav_bar_options h1 {
    font-size: 16px;
}

.nav_bar_options h1:hover,
.nav_bar_options h1:active {
    color: #fa923f;
}

.section_bar_options .nav_bar_options {
    display: inline-block;
    margin: 10px;
}

@media (max-width: 800px) {
    .main_bar {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .burger_navbar {
        padding-right: 20px;
        
        margin: 0;
        display: flex;
    }
    .logo_bar {
        padding-right: 0px;
        padding-left: 20px;
    }
    .logo_bar img{
        height: 37px;
        margin: 0px 0px;
    }
    .nav_bar_options h1 {
        font-size: 16px;
    }
    .section_bar_options {
        display: none;
    }
}