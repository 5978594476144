.drawer_main {
    height: 100%;
    background-color: white;
    opacity: 0.8;
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 301;
    left: -100%;
    animation: slide-open 0.8s forwards;
}

.sidebar.close {
    left: -100%;
}

@keyframes slide-open {
    100% {left: 0}
}


.drawer_main ul {
    padding-left: 0;
}

.sideDrawer_options h1{
    color: rgba(0, 0, 0, 0.5);
    margin: 35px 0;
}

.sideDrawer_options h1:hover,
.sideDrawer_options h1:active {
    color: #fa923f;
}
