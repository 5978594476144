.contact-form {
    width: 100%;
    position: relative;
    font-family: "Mulish", sans-serif;
    padding-bottom: 80px;
    background-color: #b0b0b0;
}

.contact_sections {
    width: 100%;
    padding-bottom: 20px;
    margin: auto;
}

.other_color_rectangle {
    width: 100%;
    height: 50px;
    background-color: #dfdddd;
    position: relative;
    z-index: 1;
}

#contact_1 {

}

.contact_sections input{
    top: -15px;
    width: 40%;
    margin: auto;
    border-radius: 5px;
    border-style: hidden;
    height: 32px;
    padding-left: 10px;
    z-index: 100;
    position: relative;
}

.contact_sections textarea{
    width: 40%;
    height: 150px;
    margin: auto;
    border-radius: 5px;
    border-style: hidden;
    padding-left: 10px;
    top: -15px;
    position: relative; 
}

.contact_btn {
    width: 40%;
    border-radius: 5px;
    border-style: hidden;
    top: -15px;
    position: relative;
    height: 32px;
}

@media (max-width: 700px) {
    .contact_sections input{
        top: -15px;
        width: 70%;
        margin: auto;
        border-radius: 5px;
        border-style: hidden;
        height: 32px;
        padding-left: 10px;
        z-index: 100;
        position: relative;
    }
    
    .contact_sections textarea{
        width: 70%;
        height: 150px;
        margin: auto;
        border-radius: 5px;
        border-style: hidden;
        padding-left: 10px;
        top: -15px;
        position: relative; 
    }
    
    .contact_btn {
        width: 70%;
        border-radius: 5px;
        border-style: hidden;
        top: -15px;
        position: relative;
        height: 32px;
    }
}