.main_options_section {
    width: 100%;
    max-width: 1900px;
    margin: auto;
    height: auto;
    position: relative;
    font-family: "Mulish", sans-serif;
}

.options_section {
    width: 95%;
    margin: auto;
    height: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 60px 30px 30px 30px;
}

.options_items {
    width: 23%;
    height: 80%;
}

.options_items img{
    width: 100%;
    height: 75%;
    object-fit: cover;
    object-position: bottom;
    border-radius: 3px;
}

.options_items h1{
    font-size: 19pt;
    margin: 18px 0 18px 0;
    text-align: left;
    font-weight: bold;
    color: black;
    opacity: 0.7;
}

.options_items h2{
    font-size: 17pt;
    margin: 18px 0 18px 0;
    text-align: left;
    color: black;
    opacity: 0.75;
}

@media(max-width: 1500px) {
    .options_items h1{
        font-size: 15pt;
    }
    
    .options_items h2{
        font-size: 12pt;
    }

}

@media(max-width: 1440px) {
    .options_items h1{
        font-size: 12pt;
    }
    
    .options_items h2{
        font-size: 10pt;
    }

    .main_options_section {
        height: auto;
    }

}

@media(max-width: 700px) {
    
    .options_section {
        flex-direction: column;
        margin: 50px 10px;
    }

    .options_items {
        width: 80%;
        margin: auto;
        height: auto;
    }
    
    .options_items h1{
        font-size: 11pt;
        margin: 10px 0;
    }
    
    .options_items h2{
        font-size: 9pt;
        margin: 10px 0 20px 0 ;
    }

    .main_options_section {
        height: auto;
    }

}
