.nosotros_section {
    width: 100%;
    margin: auto;
    height: 950px;
    font-family: "Mulish", sans-serif;
}

.nosotros_section h1{
    font-size: 80px;
    font-weight: lighter;
    clear: black;
    opacity: 0.8;
    margin: 5% 15%;
    text-align: left;
}

.staff_section {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
}

.staff {
    width: 23%;
    height: 800px;
    border: 1px solid rgba(0, 0, 0, 0.0 );
    border-radius: 4px;
}

.staff img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.staff h2 {
    font-size: 23px;
    font-weight: bold;
    margin-top: 8%;
}

.staff h3 {
    font-size: 20px;
    font-style: italic;
    font-weight: lighter;
}

.staff h4 {
    font-size: 18px;
    font-weight: lighter;
    font-style: italic;
    margin: 10% 5%;
}

.staff span {
    width: 50px;
    background-color: black;
    opacity: 0.7;
    height: 1px;
}

@media (max-width: 1440px) {
    .nosotros_section {
        height: 800px; 
    }
    .nosotros_section h1{
        font-size: 60px;
    }

    .staff h2 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
    }
    
    .staff h3 {
        font-size: 17px;
        font-style: italic;
        font-weight: lighter;
    }
    
    .staff h4 {
        font-size: 16px;
        font-weight: lighter;
        font-style: italic;
        margin: 10% 5%;
    }
}

@media (max-width:700px) {
    .nosotros_section {
        height: 2000px; 
    }
    
    .staff_section {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
    }

    .nosotros_section h1{
        font-size: 50px;
        text-align: center;
        margin: 40px 0;
    }

    .staff {
        width: 90%;
        height: 620px;
        border: 1px solid rgba(0, 0, 0, 0.0 );
        border-radius: 4px;
        margin: auto;
    }

    .staff h2 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 7%;
    }
    
    .staff h3 {
        font-size: 17px;
        font-style: italic;
        font-weight: lighter;
    }
    
    .staff h4 {
        font-size: 16px;
        font-weight: lighter;
        font-style: italic;
        margin: 8% 5%;
    }
}
