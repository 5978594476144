.proyectos_main {
    width: 100%;
    margin: auto;
}

.proyectos_main img {
    width: 99%;
    margin-bottom: 80px;
    vertical-align: center;
    align-content: center;
}

.proyectos_main h1 {
    font-size: 55px;
    color: black;
    opacity: 0.7;
    font-weight: normal;
    margin: 60px 50px;
    text-align: left;
}

@media (max-width: 700px) {
    .proyectos_main h1 {
        font-size: 35px;
        margin: 50px 20px;
    }
}