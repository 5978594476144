.clientes_container {
    width: 100%;
    margin: auto;
    margin-top: 0px;
    height: auto;
    background-color: #dfdddd;
    font-family: "Mulish", sans-serif;
}

.clientes_container h1 {
    font-size: 65px;
    text-align: left;
    padding: 50px 0 50px 100px;
    font-weight: lighter;
}

.container_logos {
    width: 80%;
    margin: auto;
    padding-bottom: 100px;
}

.clientes_logos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}

.clientes_logos img {
    width: 175px;
    margin: 20px;
}

@media (max-width: 1500px) {
    .clientes_container {

    }
    
    .clientes_container h1 {
        font-size: 50px;
    }
    
    .container_logos {

    }
    
    .clientes_logos {

    }
    
    .clientes_logos img {
        width: 110px;
        margin: 30px;
    }
}

@media (max-width: 1400px) {
    .clientes_container {

    }
    
    .clientes_container h1 {
        font-size: 40px;
    }
    
    .container_logos {

    }
    
    .clientes_logos {

    }
    
    .clientes_logos img {
        width: 100px;
        margin: 15px;
    }
}

@media (max-width: 700px) {
    .clientes_container {
        margin-top: 0px;
    }
    
    .clientes_container h1 {
        font-size: 30px;
        padding: 40px 40px 25px 40px;
    }
    
    .container_logos {
        padding-bottom: 50px;
    }
    
    .clientes_logos {

    }
    
    .clientes_logos img {
        width: 100px;
        margin: 15px;
    }
}